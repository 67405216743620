/* eslint-disable */
// @ts-nocheck
// @ts-ignore

export const SHOW_SUB_CATEGORY_LIST = [
    'brandmall'
]

export const LOADING_TIME = 500;
export const LAYOUT_KEY = 'layout';

export const CategoryDisplayMode = {
    PRODUCTS: 'PRODUCTS',
    CMS_BLOCK: 'PAGE',
    BOTH: 'PRODUCTS_AND_PAGE',
}

export const CategoryPageLayout = {
    GRID: 'grid',
    LIST: 'list',
}

export const SortDirections = {
    ASC: 'ASC',
    DESC: 'DESC',
}
