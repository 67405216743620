Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/faqs/src/plugin/ProductListQuery.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
import { Field, InlineFragment, Query } from "@tilework/opus";

import { SortDirections } from "Route/CategoryPage/CategoryPage.config";
import { CUSTOMER } from "Route/MyAccount/MyAccount.config";
import { NONE_SORT_OPTION_VALUE } from "Route/SearchPage/SearchPage.config";
import BrowserDatabase from "Util/BrowserDatabase";

/**
 * Product List Query
 * @class ProductListQuery
 * @namespace Query/ProductList/Query */
export class ProductListQuery {
  options = {};

  getQuery(options) {
    if (!options) {
      throw new Error("Missing argument `options`");
    }

    this.options = options;

    return this._getProductsField();
  }

  _getProductsField() {
    const products = new Query("products").addFieldList(
      this._getProductFields()
    );
    this._getProductArguments().forEach((arg) => products.addArgument(...arg));

    return products;
  }

  _getPriceFilter(key, value) {
    const [from, to] = value[0].split("_");

    if (from === "*") {
      return { [key]: { to } };
    }

    if (to === "*") {
      return { [key]: { from } };
    }

    return {
      [key]: { from, to },
    };
  }

  _getCustomFilters(filters = {}) {
    return Object.entries(filters).reduce((acc, [key, attribute]) => {
      if (!attribute.length) {
        return acc;
      }

      if (key === "price") {
        return {
          ...acc,
          ...this._getPriceFilter(key, attribute),
        };
      }

      return {
        ...acc,
        [key]: { in: attribute },
      };
    }, {});
  }

  _getFilterArgumentMap() {
    return {
      categoryIds: (id) => ({ category_id: { eq: id } }),
      categoryUrlPath: (url) => ({ category_url_path: { eq: url } }),
      priceRange: ({ min, max }) => {
        const price = {};

        if (min) {
          price.from = min;
        }

        if (max) {
          price.to = max;
        }

        return { price };
      },
      productsSkuArray: (sku) => ({ sku: { in: sku } }),
      productSKU: (sku) => ({ sku: { eq: sku } }),
      productID: (id) => ({ id: { eq: id } }),
      productUrlPath: (url) => ({ url_key: { eq: url } }),
      customFilters: this._getCustomFilters.bind(this),
      newToDate: (date) => ({ news_to_date: { gteq: date } }),
      conditions: (conditions) => ({ conditions: { eq: conditions } }),
      customerGroupId: (id) => ({ customer_group_id: { eq: id } }),
    };
  }

  _getArgumentsMap() {
    const { requireInfo } = this.options;
    const filterArgumentMap = this._getFilterArgumentMap();

    return {
      currentPage: {
        type: "Int!",
        handler: undefined,
      },
      pageSize: {
        type: "Int!",
        handler: (option) => (requireInfo ? 1 : option),
      },
      search: {
        type: "String!",
        handler: (option) => option.replace(/\+/g, " "),
      },
      sort: {
        type: "ProductAttributeSortInput!",
        handler: ({ sortKey, sortDirection }) => {
          if (!sortKey || sortKey === NONE_SORT_OPTION_VALUE) {
            return {};
          }

          return {
            [sortKey]: sortDirection || SortDirections.ASC,
          };
        },
      },
      filter: {
        type: "ProductAttributeFilterInput!",
        handler: (initialOptions = {}) => {
          // add customer group by default to all requests
          const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};

          const options = {
            ...initialOptions,
            customerGroupId: group_id || 0,
          };

          const { customFilters: { category_id } = {} } = options;

          /**
           * Remove category ID from select, if there is a custom filter
           * of category already selected in filtering options.
           */
          if (category_id) {
            // eslint-disable-next-line fp/no-delete
            options.categoryIds = undefined;
          }

          const parsedOptions = Object.entries(options).reduce(
            (acc, [key, option]) => {
              // if there is no value, or if the key is just not present in options object
              if (!option || !filterArgumentMap[key]) {
                return acc;
              }

              return {
                ...acc,
                ...filterArgumentMap[key](option),
              };
            },
            {}
          );

          return parsedOptions;
        },
      },
    };
  }

  _getProductArguments() {
    const { args = [] } = this.options;
    const argumentMap = this._getArgumentsMap();

    return Object.entries(args).reduce((acc, [key, arg]) => {
      if (!arg) {
        return acc;
      }

      const { type, handler = (option) => option } = argumentMap[key];

      return [...acc, [key, type, handler(arg)]];
    }, []);
  }

  _getProductFields() {
    const { requireInfo, isSingleProduct, notRequireInfo } = this.options;

    // do not request total count for PDP
    if (isSingleProduct || notRequireInfo) {
      return [this._getItemsField()];
    }

    // for filters only request
    if (requireInfo) {
      return [this._getSortField(), this._getAggregationsField()];
    }

    return [
      new Field("total_count"),
      this._getItemsField(),
      this._getPageInfoField(),
    ];
  }

  _getCartProductInterfaceFields() {
    return [
      new Field("uid"),
      new Field("id"),
      new Field("sku"),
      new Field("name"),
      new Field("type_id"),
      new Field("stock_status"),
      new Field("url"),
      new Field("salable_qty"),
      this._getStockItemField(),
      this._getProductThumbnailField(),
      this._getCartConfigurableProductFragment(),
      this._getAttributesField(false, true),
      this._getProductLinksField(),
    ];
  }

  _getCartConfigurableProductFragment() {
    return new InlineFragment("ConfigurableProduct").addFieldList([
      this._getConfigurableOptionsField(),
      this._getCartVariantsField(),
    ]);
  }

  _getCartVariantsField() {
    return new Field("variants", true).addFieldList(
      this._getCartVariantFields()
    );
  }

  _getCartVariantFields() {
    return [this._getCartProductField()];
  }

  _getCartProductField() {
    return new Field("product").addFieldList(this._getCartProductFields());
  }

  _getCartProductFields() {
    return [
      new Field("id"),
      new Field("sku"),
      new Field("stock_status"),
      new Field("salable_qty"),
      this._getStockItemField(),
      this._getProductThumbnailField(),
      this._getAttributesField(true, true),
    ];
  }

  _getfbtProductFields(
    isVariant,
    isForWishlist = false
  ) {
    const {
      isPlp = false,
    } = this.options;

    if (isForWishlist) {
      this.options.isForWishlist = true;
    }

    const fields = [
      new Field("uid"),
      new Field("id"),
      new Field("sku"),
      new Field("name"),
      new Field("type_id"),
      new Field("stock_status"),
      new Field("salable_qty"),
      new Field("url"),
      this._getStockItemField(),
      this._getPriceRangeField()
    ];

    if (!(isPlp && isVariant) || isForWishlist) {
      fields.push(
        this._getProductImageField(),
        this._getProductThumbnailField(),
        new Field("special_from_date"),
        new Field("special_to_date"),
        this._getTierPricesField()
      );
    }

    return fields;
  }
  _getFbtproducts(
    isVariant,
    isForWishlist = false
  ) {
    return new Field("mp_fbt_products", true).addFieldList(
      this._getfbtProductFields(isVariant, isForWishlist)
    );
  }
  _getProductInterfaceFields(
    isVariant,
    isForLinkedProducts = false,
    isForWishlist = false
  ) {
    const {
      isPlp = false,
      isSingleProduct,
      noAttributes = false,
      noVariants = false,
      noVariantAttributes = false,
    } = this.options;

    if (isForWishlist) {
      this.options.isForWishlist = true;
    }

    const fields = [
      new Field("uid"),
      new Field("id"),
      new Field("sku"),
      new Field("name"),
      new Field("type_id"),
      new Field("stock_status"),
      new Field("salable_qty"),
      this._getStockItemField(),
      this._getPriceRangeField(),
      new Field("entity_id"),
    ];

    if (!(isPlp && isVariant) || isForWishlist) {
      fields.push(
        this._getProductImageField(),
        this._getProductThumbnailField(),
        this._getProductSmallField(),
        this._getShortDescriptionField(),
        new Field("special_from_date"),
        new Field("special_to_date"),
        this._getTierPricesField()
      );
    }

    // if it is normal product and we need attributes
    // or if, it is variant, but we need variant attributes or variants them-self
    if (
      (!isVariant && !noAttributes) ||
      (isVariant && !noVariantAttributes && !noVariants)
    ) {
      fields.push(this._getAttributesField(isVariant, false));
    }

    // to all products (non-variants)
    if (!isVariant) {
      fields.push(
        new Field("url"),
        this._getUrlRewritesFields(),
        this._getReviewCountField(),
        this._getRatingSummaryField(),
        this._getCustomizableProductFragment()
      );

      // if variants are not needed
      if (!noVariants) {
        fields.push(
          this._getConfigurableProductFragment(),
          this._getBundleProductFragment(),
          this._getGroupedProductItems()
        );
      }
    }

    // prevent linked products from looping
    if (isForLinkedProducts) {
      fields.push(this._getProductLinksField());
    }

    // additional information to PDP loads
    if (isSingleProduct) {
      fields.push(
        new Field("stock_status"),
        this._getDescriptionField(),
        this._getMediaGalleryField(),
        this._getSimpleProductFragment()
      );
      if (!isVariant) {
        fields.push(
          new Field("canonical_url"),
          new Field("meta_title"),
          new Field("meta_keyword"),
          new Field("meta_description"),
          this._getCategoriesField(),
          this._getFbtproducts(isVariant, isForWishlist),
          this._getReviewsField(),
          this._getVirtualProductFragment(),
          this._getCustomizableProductFragment(),
          this._getProductLinksField()
        );
      }
    }

    return fields;
  }

  /**
   * For grouped products, returns the subfields of the elements of the `items` field
   * @returns {*[]}
   * @private
   */
  _getGroupedProductItemFields() {
    return [this._getProductField(), new Field("position"), new Field("qty")];
  }

  /**
   * A GroupedProduct-specific field that queries the products that are grouped under this product
   * @returns {Field}
   * @protected
   */
  _getGroupedProductItems() {
    return new InlineFragment("GroupedProduct").addField(
      new Field("items", true).addFieldList(this._getGroupedProductItemFields())
    );
  }

  /**
   * A DownloadableProduct-specific field that queries the links and samples
   * @returns {Field}
   * @private
   */
  _getDownloadableProductFields() {
    return new InlineFragment("DownloadableProduct").addFieldList(
      this._getDownloadableProductLinks()
    );
  }

  _getDownloadableProductLinks() {
    return [
      new Field("links_title"),
      new Field("samples_title"),
      new Field("links_purchased_separately"),
      this._getDownloadableProductLinkField(),
      this._getDownloadableProductSampleField(),
    ];
  }

  _getDownloadableProductLinksRequired() {
    return new InlineFragment("DownloadableProduct").addFieldList(
      this._getDownloadableProductLinksRequiredFields()
    );
  }

  _getDownloadableProductLinksRequiredFields() {
    return [new Field("links_purchased_separately")];
  }

  _getDownloadableProductLinkField() {
    return new Field("downloadable_product_links", true).addFieldList(
      this._getDownloadableProductLinkFields()
    );
  }

  _getDownloadableProductLinkFields() {
    return [
      new Field("sample_url"),
      new Field("sort_order"),
      new Field("title"),
      new Field("id"),
      new Field("uid"),
      new Field("price"),
    ];
  }

  _getDownloadableProductSampleField() {
    return new Field("downloadable_product_samples", true).addFieldList(
      this._getDownloadableProductSampleFields()
    );
  }

  _getDownloadableProductSampleFields() {
    return [
      new Field("title"),
      new Field("sort_order"),
      new Field("sample_url"),
    ];
  }

  _getItemsField() {
    const { isSingleProduct } = this.options;

    const items = new Field("items", true).addFieldList(
      this._getProductInterfaceFields(false)
    );

    if (isSingleProduct) {
      // items.addField(this._getGroupedProductItems());
      items.addField(this._getDownloadableProductFields());
    } else {
      items.addField(this._getDownloadableProductLinksRequired());
    }

    return items;
  }

  _getProductField() {
    const { isForLinkedProducts, isForWishlist = false } = this.options;

    return new Field("product").addFieldList(
      this._getProductInterfaceFields(true, isForLinkedProducts, isForWishlist)
    );
  }

  _getShortDescriptionFields() {
    return [new Field("html")];
  }

  _getShortDescriptionField() {
    return new Field("short_description").addFieldList(
      this._getShortDescriptionFields()
    );
  }

  _getStockItemField() {
    return new Field("stock_item").addFieldList(this._getStockItemFields());
  }

  _getStockItemFields() {
    return [
      new Field("in_stock"),
      new Field("min_sale_qty"),
      new Field("max_sale_qty"),
      new Field("qty_increments"),
    ];
  }

  _getBreadcrumbFields() {
    return [
      new Field("category_id"),
      new Field("category_name"),
      new Field("category_level"),
      new Field("category_url"),
      new Field("category_is_active"),
    ];
  }

  _getBreadcrumbsField() {
    return new Field("breadcrumbs", true).addFieldList(
      this._getBreadcrumbFields()
    );
  }

  _getCategoryFields() {
    return [
      new Field("id"),
      new Field("name"),
      new Field("url"),
      this._getIsCategoryActive(),
      this._getBreadcrumbsField(),
    ];
  }

  _getIsCategoryActive() {
    return new InlineFragment("CategoryTree").addField("is_active");
  }

  _getCategoriesField() {
    return new Field("categories", true).addFieldList(
      this._getCategoryFields()
    );
  }

  _getMinimalPriceFields() {
    return [
      this._getDiscountField(),
      this._getFinalPriceField(),
      this._getFinalPriceExclTaxField(),
      this._getRegularPriceField(),
      this._getRegularPriceExclTaxField(),
      this._getDefaultPriceField(),
      this._getDefaultFinalPriceField(),
      this._getDefaultFinalPriceExclTaxField(),
    ];
  }

  _getfbtMinimalPriceFields() {
    return [
      this._getDiscountField(),
      this._getFinalPriceField(),
      this._getFinalPriceExclTaxField(),
      this._getRegularPriceField(),
      this._getRegularPriceExclTaxField(),
      this._getDefaultPriceField(),
      this._getDefaultFinalPriceField(),
      this._getDefaultFinalPriceExclTaxField(),
    ];
  }
  _getMinimalPriceField() {
    return new Field("minimum_price").addFieldList(
      this._getMinimalPriceFields()
    );
  }

  _getMaximalPriceField() {
    return new Field("maximum_price").addFieldList(
      this._getMinimalPriceFields()
    );
  }
  _getfbtMinimalPriceField() {
    return new Field("minimum_price").addFieldList(
      this._getfbtMinimalPriceFields()
    );
  }

  _getfbtMaximalPriceField() {
    return new Field("maximum_price").addFieldList(
      this._getfbtMinimalPriceFields()
    );
  }
  _getPriceRangeFields() {
    // Using an array as potentially would want to add maximum price
    return [this._getMinimalPriceField(), this._getMaximalPriceField()];
  }
  _getfbtPriceRangeFields() {
    // Using an array as potentially would want to add maximum price
    return [this._getfbtMinimalPriceField(), this._getfbtMaximalPriceField()];
  }

  _getPriceRangeField() {
    return new Field("price_range").addFieldList(this._getPriceRangeFields());
  }
  _getfbtPriceRangeField() {
    return new Field("price_range").addFieldList(
      this._getfbtPriceRangeFields()
    );
  }

  /**
   * @returns {[string]} an array representing the subfields of the product thumbnail
   * @private
   */
  _getProductThumbnailFields() {
    return [new Field("path"), new Field("url")];
  }
  _getFbtproductsimagefields() {
    return [new Field("path"), new Field("url")];
  }
  _getProductSmallFields() {
    return this._getProductThumbnailFields();
  }

  /**
   * Returns the field for fetching the thumbnail of a product.
   * Not to be confused with the media thumbnail field, which has the same name but is a subfield of media_gallery_entries
   * @returns {Field}
   * @private
   */
  _getProductThumbnailField() {
    return new Field("thumbnail").addFieldList(
      this._getProductThumbnailFields()
    );
  }

  _getProductSmallField() {
    return new Field("small_image").addFieldList(this._getProductSmallFields());
  }

  _getProductImageField() {
    return new Field("image").addFieldList(this._getProductThumbnailFields());
  }
  _getFbtproductsimagefield() {
    return new Field("image").addFieldList(this._getFbtproductsimagefields());
  }

  _getAttributeOptionField(noSwatches) {
    const fields = [new Field("label"), new Field("value")];

    if (!noSwatches) {
      fields.push(this._getSwatchDataField());
    }

    return fields;
  }

  _getAttributeOptionsField(noSwatches) {
    return new Field("attribute_options", true).addFieldList(
      this._getAttributeOptionField(noSwatches)
    );
  }

  _getAdditionalAttributeFields(isCart) {
    if (isCart) {
      return [];
    }

    return [
      new Field("attribute_type"),
      new Field("attribute_group_id"),
      new Field("attribute_group_name"),
    ];
  }

  _getAttributeOptionsFields(isVariant) {
    if (isVariant) {
      return [];
    }

    return [this._getAttributeOptionsField(false)];
  }

  _getAttributeFields(isVariant = false, isCart = false) {
    return [
      new Field("attribute_id"),
      new Field("attribute_value"),
      new Field("attribute_code"),
      new Field("attribute_label"),
      ...this._getAdditionalAttributeFields(isCart),
      ...this._getAttributeOptionsFields(isVariant),
    ];
  }

  _getAttributesField(isVariant, isCart) {
    return new Field("s_attributes", true)
      .setAlias("attributes")
      .addFieldList(this._getAttributeFields(isVariant, isCart));
  }

  _getMediaGalleryFields() {
    return [
      new Field("id"),
      new Field("file"),
      new Field("label"),
      new Field("position"),
      new Field("disabled"),
      new Field("media_type"),
      new Field("types", true),
      this._getVideoContentField(),
      this._getMediaThumbnailField(),
      this._getMediaBaseField(),
      this._getMediaLargeField(),
    ];
  }

  /**
   * Returns a field querying video-specific data for a media gallery entry.
   * @returns {Field} the video_content field
   * @private
   */
  _getVideoContentField() {
    return new Field("video_content").addFieldList([
      new Field("media_type"),
      new Field("video_description"),
      new Field("video_metadata"),
      new Field("video_provider"),
      new Field("video_title"),
      new Field("video_url"),
    ]);
  }

  /**
   * Returns a field querying the thumbnail of a media gallery entry.
   * Not to be confused with the product thumbnail field, which has the same name but is a direct subfield of the product
   * @returns {Field}
   * @private
   */
  _getMediaThumbnailField() {
    return new Field("thumbnail").addField("url");
  }

  _getMediaBaseField() {
    return new Field("base").addField("url");
  }

  _getMediaLargeField() {
    return new Field("large").addField("url");
  }

  _getMediaGalleryField() {
    return new Field("media_gallery_entries", true).addFieldList(
      this._getMediaGalleryFields()
    );
  }

  _getProductLinksField() {
    return new Field("product_links", true).addFieldList(
      this._getProductLinkFields()
    );
  }

  _getDescriptionFields() {
    return [new Field("html")];
  }

  _getDescriptionField() {
    return new Field("description").addFieldList(this._getDescriptionFields());
  }

  _getUrlRewritesFields() {
    return new Field("url_rewrites").addFieldList([new Field("url")]);
  }

  _getProductLinkFields() {
    return [
      new Field("position"),
      new Field("link_type"),
      new Field("linked_product_sku"),
    ];
  }

  _getRatingsBreakdownFields() {
    return [new Field("name").setAlias("rating_code"), new Field("value")];
  }

  _getRatingsBreakdownField() {
    return new Field("ratings_breakdown", true)
      .setAlias("rating_votes")
      .addFieldList(this._getRatingsBreakdownFields());
  }

  _getReviewItemsFields() {
    return [
      new Field("average_rating"),
      new Field("nickname"),
      new Field("summary").setAlias("title"),
      new Field("text").setAlias("detail"),
      new Field("created_at"),
      this._getRatingsBreakdownField(),
    ];
  }

  _getReviewsFields() {
    return [this._getReviewItemsField()];
  }
  _getReviewItemsField() {
    return new Field("items", true).addFieldList(this._getReviewItemsFields());
  }

  _getReviewsField() {
    return new Field("reviews")

      .addArgument("pageSize", "Int", 20)
      .addArgument("currentPage", "Int", 1)
      .addFieldList(this._getReviewsFields());
  }

  _getReviewCountField() {
    return new Field("review_count");
  }

  _getRatingSummaryField() {
    return new Field("rating_summary");
  }

  _getBundleOptionsFields() {
    return [
      new Field("uid"),
      new Field("label"),
      new Field("quantity"),
      new Field("position"),
      new Field("is_default"),
      new Field("price"),
      new Field("price_type"),
      new Field("can_change_quantity"),
      this._getProductBundleOptionField(),
    ];
  }

  _getProductBundleOptionField() {
    return new Field("product").addFieldList(
      this._getProductBundleOptionFields()
    );
  }

  _getProductBundleOptionFields() {
    return [
      new Field("name"),
      new Field("stock_status"),
      this._getPriceRangeField(),
    ];
  }

  _getBundleOptionsField() {
    return new Field("options", true).addFieldList(
      this._getBundleOptionsFields()
    );
  }

  _getBundleItemsFields() {
    return [
      new Field("uid"),
      new Field("option_id"),
      new Field("title"),
      new Field("required"),
      new Field("type"),
      new Field("position"),
      new Field("sku"),
      this._getBundleOptionsField(),
    ];
  }

  _getBundleItemsField() {
    return new Field("items", true).addFieldList(this._getBundleItemsFields());
  }

  _getBundlePriceOptionSelectionFields() {
    return [
      new Field("selection_id"),
      new Field("final_option_price"),
      new Field("final_option_price_excl_tax"),
      new Field("regular_option_price"),
      new Field("regular_option_price_excl_tax"),
    ];
  }

  _getBundlePriceOptionFields() {
    return [
      new Field("option_id"),
      new Field("selection_details", true).addFieldList(
        this._getBundlePriceOptionSelectionFields()
      ),
    ];
  }

  _getBundlePriceOptionsField() {
    return new Field("bundle_options", true).addFieldList(
      this._getBundlePriceOptionFields()
    );
  }

  _getBundleProductFragmentFields() {
    return [
      new Field("dynamic_price"),
      new Field("dynamic_sku"),
      new Field("ship_bundle_items"),
      new Field("dynamic_weight"),
      this._getBundleItemsField(),
      this._getBundlePriceOptionsField(),
    ];
  }

  _getValueFields() {
    return [new Field("value_index")];
  }

  _getValuesField() {
    return new Field("values", true).addFieldList(this._getValueFields());
  }

  _getConfigurableOptionFields() {
    return [new Field("attribute_code"), this._getValuesField()];
  }

  _getConfigurableOptionsField() {
    return new Field("configurable_options", true).addFieldList(
      this._getConfigurableOptionFields()
    );
  }

  _getVariantFields() {
    return [this._getProductField()];
  }

  _getVariantsField() {
    const { isPlp = false, isForWishlist = false } = this.options;

    // For PLP page we have optimized variants graphql field
    const variantsField = isPlp && !isForWishlist ? "variants_plp" : "variants";

    return new Field(variantsField, true)
      .setAlias("variants")
      .addFieldList(this._getVariantFields());
  }

  _getConfigurableProductFragmentFields() {
    return [this._getConfigurableOptionsField(), this._getVariantsField()];
  }

  _getCustomizableTextValueFields() {
    return [
      new Field("price"),
      new Field("priceInclTax"),
      new Field("priceExclTax"),
      new Field("price_type"),
      new Field("currency"),
      new Field("sku"),
      new Field("max_characters"),
    ];
  }

  _getCustomizableTextValueField(alias) {
    return new Field("value")
      .addFieldList(this._getCustomizableTextValueFields())
      .setAlias(alias);
  }

  _getCustomizableTextFields(alias) {
    return [
      this._getCustomizableTextValueField(alias),
      new Field("product_sku"),
    ];
  }

  _getCustomizableFileValueField(alias) {
    return new Field("value", true)
      .addFieldList([
        new Field("price"),
        new Field("priceInclTax"),
        new Field("priceExclTax"),
        new Field("price_type"),
        new Field("currency"),
        new Field("sku"),
        new Field("file_extension"),
      ])
      .setAlias(alias);
  }

  _getCustomizableAreaOption() {
    return new InlineFragment("CustomizableAreaOption").addFieldList(
      this._getCustomizableTextFields("areaValues")
    );
  }

  _getCustomizableFieldOption() {
    return new InlineFragment("CustomizableFieldOption").addFieldList(
      this._getCustomizableTextFields("fieldValues")
    );
  }

  _getCustomizableFileOption() {
    return new InlineFragment("CustomizableFileOption").addFieldList([
      this._getCustomizableFileValueField("fileValues"),
    ]);
  }

  _getCustomizableDateValueFields() {
    return [
      new Field("price"),
      new Field("priceInclTax"),
      new Field("priceExclTax"),
      new Field("price_type"),
      new Field("currency"),
      new Field("sku"),
    ];
  }

  _getCustomizableDateValueField() {
    return new Field("value", true).addFieldList(
      this._getCustomizableDateValueFields()
    );
  }

  _getCustomizableDateFields() {
    return [this._getCustomizableDateValueField(), new Field("product_sku")];
  }

  _getCustomizableDateOption() {
    return new InlineFragment("CustomizableDateOption").addFieldList(
      this._getCustomizableDateFields()
    );
  }

  _getCustomizableSelectionValueFields() {
    return [
      new Field("uid"),
      new Field("option_type_id"),
      new Field("price"),
      new Field("priceInclTax"),
      new Field("priceExclTax"),
      new Field("price_type"),
      new Field("currency"),
      new Field("sku"),
      new Field("title"),
      new Field("sort_order"),
    ];
  }

  _getCustomizableSelectionValueField(alias) {
    return new Field("value", true)
      .addFieldList(this._getCustomizableSelectionValueFields())
      .setAlias(alias);
  }

  _getCustomizableCheckboxOption() {
    return new InlineFragment("CustomizableCheckboxOption").addFieldList([
      this._getCustomizableSelectionValueField("checkboxValues"),
    ]);
  }

  _getCustomizableMultiOption() {
    return new InlineFragment("CustomizableMultipleOption").addFieldList([
      this._getCustomizableSelectionValueField("checkboxValues"),
    ]); // same as checkbox
  }

  _getCustomizableDropdownOption() {
    return new InlineFragment("CustomizableDropDownOption").addFieldList([
      this._getCustomizableSelectionValueField("dropdownValues"),
    ]);
  }

  _getCustomizableRadioOption() {
    return new InlineFragment("CustomizableRadioOption").addFieldList([
      this._getCustomizableSelectionValueField("dropdownValues"),
    ]); // same as dropdown
  }

  _getCustomizableProductFragmentOptionsFields() {
    return [
      this._getCustomizableDropdownOption(),
      this._getCustomizableRadioOption(),
      this._getCustomizableCheckboxOption(),
      this._getCustomizableMultiOption(),
      this._getCustomizableFieldOption(),
      this._getCustomizableAreaOption(),
      this._getCustomizableFileOption(),
      this._getCustomizableDateOption(),
      new Field("title"),
      new Field("required"),
      new Field("sort_order"),
      new Field("type"),
      new Field("uid"),
    ];
  }

  _getCustomizableProductFragmentOptionsField() {
    return new Field("options", true).addFieldList(
      this._getCustomizableProductFragmentOptionsFields()
    );
  }

  _getCustomizableProductFragment() {
    return new InlineFragment("CustomizableProductInterface").addFieldList([
      this._getCustomizableProductFragmentOptionsField(),
    ]);
  }

  _getSimpleProductFragmentFields() {
    return [this._getTierPricesField()];
  }

  _getVirtualProductFragmentFields() {
    return [this._getTierPricesField()];
  }

  _getTierPricesField() {
    return new Field("price_tiers", true).addFieldList(
      this._getTierPricesFields()
    );
  }

  _getTierPricesFields() {
    return [
      this._getDiscountField(),
      this._getFinalPriceField(),
      new Field("quantity"),
    ];
  }

  _getDiscountField() {
    return new Field("discount")
      .addField(new Field("amount_off"))
      .addField(new Field("percent_off"));
  }

  _getFinalPriceField() {
    return new Field("final_price")
      .addField(new Field("currency"))
      .addField(new Field("value"));
  }

  _getFinalPriceExclTaxField() {
    return new Field("final_price_excl_tax")
      .addField(new Field("currency"))
      .addField(new Field("value"));
  }

  _getRegularPriceField() {
    return new Field("regular_price")
      .addField(new Field("currency"))
      .addField(new Field("value"));
  }

  _getRegularPriceExclTaxField() {
    return new Field("regular_price_excl_tax")
      .addField(new Field("currency"))
      .addField(new Field("value"));
  }

  _getDefaultFinalPriceExclTaxField() {
    return new Field("default_final_price_excl_tax")
      .addField(new Field("currency"))
      .addField(new Field("value"));
  }

  _getDefaultPriceField() {
    return new Field("default_price")
      .addField(new Field("currency"))
      .addField(new Field("value"));
  }

  _getDefaultFinalPriceField() {
    return new Field("default_final_price")
      .addField(new Field("currency"))
      .addField(new Field("value"));
  }

  _getBundleProductFragment() {
    return new InlineFragment("BundleProduct").addFieldList(
      this._getBundleProductFragmentFields()
    );
  }

  _getConfigurableProductFragment() {
    return new InlineFragment("ConfigurableProduct").addFieldList(
      this._getConfigurableProductFragmentFields()
    );
  }

  _getSimpleProductFragment() {
    return new InlineFragment("SimpleProduct").addFieldList(
      this._getSimpleProductFragmentFields()
    );
  }

  _getVirtualProductFragment() {
    return new InlineFragment("VirtualProduct").addFieldList(
      this._getVirtualProductFragmentFields()
    );
  }

  _getSortOptionFields() {
    return [new Field("value"), new Field("label")];
  }

  _getSortOptionsField() {
    return new Field("options", true).addFieldList(this._getSortOptionFields());
  }

  _getSortFields() {
    return [this._getSortOptionsField()];
  }

  _getSortField() {
    return new Field("sort_fields").addFieldList(this._getSortFields());
  }

  _getSwatchDataFields() {
    return [new Field("type"), new Field("value")];
  }

  _getSwatchDataField() {
    return new Field("swatch_data").addFieldList(this._getSwatchDataFields());
  }

  _getAggregationsField() {
    return new Field("aggregations", true)
      .setAlias("filters")
      .addFieldList(this._getAggregationsFields());
  }

  _getAggregationsFields() {
    return [
      new Field("label").setAlias("name"),
      new Field("attribute_code").setAlias("request_var"),
      new Field("is_boolean"),
      new Field("has_swatch"),
      new Field("position"),
      this._getAggregationsOptionsField(),
    ];
  }

  _getAggregationsOptionsField() {
    return new Field("options", true)
      .setAlias("filter_items")
      .addFieldList(this._getAggregationsOptionsFields());
  }

  _getAggregationsOptionsFields() {
    return [
      new Field("label"),
      new Field("count"),
      new Field("value").setAlias("value_string"),
      this._getSwatchDataField(),
    ];
  }

  _getPageInfoField() {
    return new Field("page_info")
      .addField(new Field("current_page"))
      .addField(new Field("total_pages"));
  }
}

export default new ProductListQuery();
