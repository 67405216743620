/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
export const Page = {
    PDP: 'pdp',
    POPUP: 'popup',
    CATEGORY: 'category',
    CUSTOMER_ACCOUNT: 'customer_account',
    CUSTOMER_SUB_ACCOUNT: 'customer_sub_account',
    CUSTOMER_ACCOUNT_PAGE: 'customer_account_page',
    CUSTOMER_WISHLIST: 'wishlist',
    CUSTOMER_ORDER: 'customer_order',
    HOME_PAGE: 'home',
    MENU: 'menu',
    MENU_SUBCATEGORY: 'menu_subcategory',
    SEARCH: 'search',
    FILTER: 'filter',
    CART: 'cart',
    MOBILEACCOUNT: 'mobile_account',
    ORDERTRACKING: 'ordertracking',
    STORE_LOCATOR : 'storelocator',
    BRANDMALL : 'brandmall',
    BRANDMALL_SUB: 'brandmall_sub',
    ASAP_ELIGIBLE : 'asap-eligible',
    CART_OVERLAY: 'cart_overlay',
    CART_EDITING: 'cart_editing',
    CHECKOUT: 'checkout',
    CHECKOUT_SUCCESS: 'checkout_success',
    CHECKOUT_ACCOUNT: 'checkout_account',
    CMS_PAGE: 'cms-page',
    MY_ACCOUNT: 'my-account',
    NO_MATCH: 'no-match',
    CONTACT_US: 'contact-us',
    PRODUCT_COMPARE: 'product-compare',
    CITY_PRICING: 'CITY_PRICING'
};
